<template>
  <div class="service_details_container">
    <img class="title_image" src="../../assets/img_shipping.png" />
    <h4>START SHIPING</h4>
    <div class="step_view_wrapper">
      <div class="strip">
        <div class="strip_half" style="display:inline-block"></div>
        <div class="strip_half" style="display:inline-block"></div>
        <div class="circle">
          <div class="circle_labels">SERVICE OPTIONS</div>
        </div>
        <div class="circle two">
          <div class="circle_labels">SHIPMENT DETAILS</div>
        </div>
        <div class="circle three">
          <div class="circle_labels">CONFIRM SHIPMENT</div>
        </div>
      </div>
    </div>
    <section class="two_blocks_row">
      <div class="editable_block">
        <header>
          <h3>SENDER DETAILS</h3>
          <a class="edit_details" @click="editDetails" >
            <i class="material-icons">create</i>Edit
          </a>
        </header>
        <div class="block_contents">
          <p>{{ draft.requester_name }}</p>
          <p>{{ draft.branch_id }}</p>
          <p>{{draft.branch_address_1}}</p>
          <p>{{draft.branch_address_2}} {{draft.branch_city}}</p>
          <p>{{draft.branch_state}} {{draft.branch_postal_code}} {{draft.branch_country}}</p>
          <p><span><b>Telephone : </b></span>{{draft.branch_phone}}</p>
          <!-- <p><span><b>Email : </b></span>{{draft.branch_email}}</p> -->
        </div>
      </div>

      <div class="editable_block">
        <header>
          <h3>SHIPMENT DETAILS</h3>
          <a class="edit_details" @click="editShipmentDetails">
            <i class="material-icons">create</i>Edit
          </a>
        </header>
        <div class="block_contents">
          <p>Date of Shipment : {{ draft.pickup_date }}</p>
          <p>Type of goods :  {{ draft.type_of_good }}</p>
          <p>Number of Pieces : {{ draft.item_qty }}</p>
          <p>Total Weight : {{ draft.item_weight }} Kgs</p>
          <p>Insured Amount : {{draft.insured_value}}</p>
          <p>Description of Contents : {{draft.goods_description}}</p>
          <p>Remarks Information : {{ draft.remarks }}</p>
        </div>
      </div>
    </section>

    <section class="two_blocks_row">
      <div class="editable_block">
        <header>
          <h3>PICKUP DETAILS</h3>
          <a class="edit_details" @click="editDetails">
            <i class="material-icons">create</i>Edit
          </a>
        </header>
        <div class="block_contents">
          <p>{{draft.pickup_name}}</p>
          <p>{{ draft.pickup_branch }}</p>
          <p>{{draft.pickup_address_1}}</p>
          <p>{{draft.pickup_address_2}}, {{draft.pickup_city}}</p>
          <p>{{draft.pickup_state ? draft.pickup_state + ',':''}} {{draft.pickup_postal_code}}</p>
          <p>{{draft.pickup_country}}</p>
          <p><span><b>Telephone : </b></span>{{draft.pickup_phone}}</p>
          <p><span><b>Email : </b></span>{{draft.pickup_email}}</p>
        </div>
      </div>

      <div class="editable_block">
        <header>
          <h3>DELIVERY DETAILS</h3>
          <div class="selected_count" @click="$router.push('/pages/ServiceDetails/'+$route.params.id)">
            <i class="material-icons">account_circle</i>
            <span>{{count}} Selected</span>
          </div>
          <a class="edit_details" @click="editDetails">
            <i class="material-icons">create</i>Edit
          </a>
        </header>
        <div class="block_contents">
          <p>{{draft3.delivery_name}}</p>
          <p>{{draft3.delivery_branch}}</p>
          <p>{{draft3.delivery_address_1}}</p>
          <p>{{draft3.delivery_address_2}}, {{draft3.delivery_city}}</p>
          <p>{{draft3.delivery_state ? draft3.delivery_state + ',':''}} {{draft3.delivery_postal_code}}</p>
          <p>{{draft3.delivery_country}}</p>
          <p><span><b>Telephone : </b></span>{{draft3.delivery_phone}}</p>
          <p><span><b>Email : </b></span>{{draft3.delivery_email}}</p>
        </div>
      </div>
    </section>
    <!-- <section class="two_blocks_row">
      <div class="editable_block full">
        <header>
          <h3>NOTIFICATION</h3>
        </header>
        <div class="block_contents">
          <p>Email upon shipping</p>
          <div class="form_section1">
            <table class="conf_adj_1">
              <tr>
                <td class="series_td">
                  <div class="label_and_element_wrapper">
                    <label class="chk_label_conf">
                      <input type="checkbox" /> Shipper
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <input type="text" placeholder="Name" />
                  </div>
                </td>
                <td class="series_td">
                  <div class="label_and_element_wrapper">
                    <label class="chk_label_conf">
                      <input type="checkbox" /> Receiver
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <input type="text" placeholder="Name" />
                  </div>
                </td>
              </tr>

              <tr>
                <td class="series_td">
                  <div class="label_and_element_wrapper">
                    <label class="chk_label_conf">
                      <input type="checkbox" />
                      Pro Active Delivery Notofication
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper"></div>
                </td>
                <td class="series_td">
                  <div class="label_and_element_wrapper"></div>
                </td>
                <td>
                  <div class="label_and_element_wrapper"></div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </section> -->
      <section>
          <label class="lone_chk_label">
            <input type="checkbox" v-model="draft3.is_agreed" :true-value="true" :false-value="false"/>
            <span>I have read and agree to the terms and conditions</span>
          </label>
          <a class="tandc_link">Terms and Conditions</a>
      
      </section>
    <section class="centered">
      <a class="link_bt bt_save" @click="previous" >Previous</a>
      <a class="link_bt bt_cancel" @click="$router.push('/pages/Dashboard')" >Cancel</a>
      <a class="link_bt bt_draft" @click="draftsubmit"  :disabled="save_btn.disabled">{{save_btn.value}}</a>
      <a class="link_bt bt_save"  @click="submit" >Submit</a>
    </section>

    <div class="black-overlay" v-show="viewconsignmentpopup_booking_id">
      <div class="box-modal printPops">
        <div class="body">
          <ViewConsignment
            @closeModal="closeConsignment"
            :booking_id="viewconsignmentpopup_booking_id"
            v-if="viewconsignmentpopup_booking_id"
          />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import ViewConsignment from "./ViewConsignment.vue";

export default {
  name: "ServiceConfirm",
  components: {
    ViewConsignment
  },
  data() {
    return {
      viewconsignmentpopup_booking_id: "",
      count:"",
      draft:{
        request_date:"",
        requester_name:"",
        branch_id:"",
        type_of_good:"",
        mode_of_dispatch:"",
        carrier_code:"",
        provider_id:"",
        service_id:"",
        ptaeo:"",
        has_priority:0,
        reference_no:"",
        temp_pickup_address_id:"",
        pickup_name:"" ,
        pickup_company: "",
        pickup_branch: "",
        pickup_address_1: "",
        pickup_address_2: "",
        pickup_postal_code: "",
        pickup_city: "",
        pickup_suburb: "",
        pickup_state: "",
        pickup_country: "",
        pickup_phone: "",
        pickup_mobile: "",
        pickup_email: "",
        delivery_name:"",
        delivery_company:"",
        delivery_branch:"",
        delivery_address_1:"",
        delivery_address_2:"",
        delivery_postal_code:"",
        delivery_city:"",
        delivery_suburb:"",
        delivery_state:"",
        delivery_country:"",
        delivery_phone:"",
        delivery_mobile:"",
        delivery_email:"",
        pickup_date:"",
        pickup_time:"",
        goods_description:""
      },
      save_btn:{
        disabled:false,
        value:"SAVE AS DRAFT"
      },
      editTypeboolean:"",
      draft3:{
        is_agreed:true,
        is_draft:0,
        delivery_address:[{
          delivery_name:"",
          delivery_company:"",
          delivery_branch:"",
          delivery_address_1:"",
          delivery_address_2:"",
          delivery_postal_code:"",
          delivery_city:"",
          delivery_suburb:"",
          delivery_state:"",
          delivery_country:"",
          delivery_phone:"",
          delivery_mobile:"",
          delivery_email:""
        }]
      }
    };
  },
  created(){
    this.axios.get("/api/draft/"+this.$route.params.id)
    .then(response => {
      console.log(response);
        this.count = response.data.count;
        this.draft.request_date=response.data.draft.request_date;
        this.draft.requester_name=response.data.draft.requester_name;
        this.draft.branch_id=response.data.draft.branch.name;
        this.draft.pickup_date=response.data.draft.pickup_date;
        this.draft.type_of_good=response.data.draft.goods.type;
        this.draft.item_qty=response.data.draft.item_qty;
        this.draft.item_weight=response.data.draft.item_weight;
        this.draft.insured_value=response.data.draft.insured_value;
        this.draft.remarks=response.data.draft.remarks;
        this.draft.pickup_name=response.data.draft.pickup_name;
        this.draft.pickup_branch=response.data.draft.pickup_branch;
        this.draft.pickup_address_1=response.data.draft.pickup_address_1;
        this.draft.pickup_address_2=response.data.draft.pickup_address_2;
        this.draft.pickup_city=response.data.draft.pickup_city;
        this.draft.pickup_state=response.data.draft.pickup_state;
        this.draft.pickup_postal_code=response.data.draft.pickup_postal_code;
        this.draft.pickup_country=response.data.draft.pickup_country;
        this.draft.pickup_phone=response.data.draft.pickup_phone;
        this.draft.pickup_email=response.data.draft.pickup_email;
        this.draft3.delivery_name=response.data.draft.delivery_name;
        this.draft3.delivery_branch=response.data.draft.delivery_branch;
        this.draft3.delivery_address_1=response.data.draft.delivery_address_1;
        this.draft3.delivery_address_2=response.data.draft.delivery_address_2;
        this.draft3.delivery_city=response.data.draft.delivery_city;
        this.draft3.delivery_state=response.data.draft.delivery_state;
        this.draft3.delivery_postal_code=response.data.draft.delivery_postal_code;
        this.draft3.delivery_country=response.data.draft.delivery_country;
        this.draft3.delivery_phone=response.data.draft.delivery_phone;
        this.draft3.delivery_email=response.data.draft.delivery_email;
        this.draft.goods_description=response.data.draft.goods_description;
        this.draft.branch_address_1=response.data.draft.branch.address_1;
        this.draft.branch_address_2=response.data.draft.branch.address_2;
        this.draft.branch_city=response.data.draft.branch.city;
        this.draft.branch_state=response.data.draft.branch.state;
        this.draft.branch_postal_code=response.data.draft.branch.postal_code;
        this.draft.branch_country=response.data.draft.branch.country;
        this.draft.branch_phone=response.data.draft.branch.phone;
        this.draft.branch_email=response.data.draft.branch.email;
    })
    .catch(error =>{
      console.log(error);
    });
  },
  methods:{
    editDetails(){
      if(this.$route.params.type != undefined){
        this.editTypeboolean = this.$route.params.type;
        this.$router.push("/pages/ServiceDetails/"+this.$route.params.id+"/"+this.editTypeboolean);
      } else {
        this.$router.push("/pages/ServiceDetails/"+this.$route.params.id);
      }
    },
    editShipmentDetails(){
      if(this.$route.params.type != undefined){
        this.editTypeboolean = this.$route.params.type;
        this.$router.push("/pages/ServiceUpdate/"+this.$route.params.id+"/"+this.editTypeboolean);
      } else {
        this.$router.push("/pages/ServiceUpdate/"+this.$route.params.id);
      }
    },
    previous(){
      if(this.$route.params.type != undefined){
        this.editTypeboolean = this.$route.params.type;
        this.$router.push("/pages/ServiceUpdate/"+this.$route.params.id+"/"+this.editTypeboolean);
      } else {
        this.$router.push("/pages/ServiceUpdate/"+this.$route.params.id);
      }
    },
    submit(){
       if(!this.draft3.is_agreed){
        this.toast.error("Please Agree Terms and conditions");
       } else {
          this.draft3.is_draft = 0;
          this.axios.put("/api/draft/"+this.$route.params.id + "/last",this.draft3)
          .then(response => {
            console.log(response);
            this.viewconsignmentpopup_booking_id = response.data.courier.booking_id;
            this.toast.success(response.data.msg);
          })
          .catch(error =>{
            console.log(error);
          });
       }
    },
    draftsubmit(){
       if(!this.draft3.is_agreed){
        this.toast.error("Please Agree Terms and conditions");
       } else {
          this.draft3.is_draft = 1;
          this.save_btn.disabled = true;
          this.save_btn.value = "SAVING...";
          this.axios.put("/api/draft/"+this.$route.params.id + "/last",this.draft3)
          .then(response => {
            console.log(response);
            this.toast.success(response.data.msg);
            this.$router.push("/pages/Draft");
            this.save_btn.disabled = false;
            this.save_btn.value = "SAVE AS DRAFT";
          })
          .catch(error =>{
            console.log(error);
            this.save_btn.disabled = false;
            this.save_btn.value = "SAVE AS DRAFT";
          });
       }
    },
    closeConsignment(){
      this.viewconsignmentpopup_booking_id = '';
      this.$router.push("/pages/ViewPending");
      this.courier = this.courier.map(element => {
        element.show_view_popup = false;
        return element;
      });
    },
  }
};
</script>
<style scoped>
.service_details_container {
  padding: 20px;
}
.service_details_container > section {
  padding: 20px 0 0 0;
}
.service_details_container > section h2 {
  font-size: 20px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: inline-block;
}
.title_image {
  display: block;
  margin: auto;
}
h4 {
  font-weight: 600;
  text-align: center;
  padding: 30px 0;
  font-size: 18px;
}
.step_view_wrapper {
  width: 100%;
  height: 140px;
  background: #181818;
  padding: 20px 80px;
  position: relative;
}
.step_view_wrapper .strip {
  width: calc(100% - 160px);
  height: 10px;
  background: #666;
  position: absolute;
  top: 50%;
  line-height: 0;
}
.step_view_wrapper .strip_half {
  height: 10px;
  width: 50%;
  background: #06A5ED;
  display: inline-block;
  display: none;
}
.step_view_wrapper .circle {
  width: 30px;
  height: 30px;
  background: #000;
  border: 5px solid #06A5ED;
  border-radius: 100px;
  position: absolute;
  left: -10px;
  top: -10px;
}
.step_view_wrapper .circle.inactive {
  background: #ffffff;
  border-color: #999;
}
.step_view_wrapper .circle.two {
  left: 50%;
  margin-left: -10px;
}
.step_view_wrapper .circle.three {
  left: 100%;
  margin-left: -10px;
}
.step_view_wrapper .circle_labels {
  font-size: 14px;
  text-align: center;
  color: #fff;
  position: absolute;
  width: 120px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  top: 40px;
  left: -60px;
}

.label_and_element_wrapper.sp {
  width: 33%;
}
.lone_chk_label {
  padding: 20px;
  display: inline-block;
}
.lone_chk_label input {
  display: inline-block;
  margin-right: 10px;
}
.service_logo {
  width: 300px;
  height: auto;
  padding: 20px 0;
}
.service_logo img {
  display: block;
  width: 100%;
  height: auto;
}
.service_details_container > .two_blocks_row {
  display: flex;
  height: auto;
  padding: 40px 40px 0 40px;
}
.two_blocks_row > .editable_block {
  width: 50%;
  height: auto;
  padding: 0;
  padding-right: 30px;
}
.two_blocks_row > .editable_block:last-child {
  width: 50%;
  height: auto;
  padding: 0;
  padding-left: 30px;
}
.two_blocks_row > .editable_block.full {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.two_blocks_row header {
  padding-bottom: 10px;
  margin-bottom: 10px;
  height: 50px;
  display: flex;
  justify-content: space-between;
}
.two_blocks_row header h3 {
  font-weight: 600;
  display: block;
  line-height: 40px;
  padding-bottom: 10px;
  border-bottom: 4px solid #666;
}
.two_blocks_row header .edit_details {
  display: inline-block;
  text-align: center;
  background: #06A5ED;
  color: #fff;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: left;
  margin: auto 0 0 auto;
}
.two_blocks_row header .edit_details i {
  display: inline-block;
  position: relative;
  top: 5px;
  margin-left: 15px;
}
.two_blocks_row .block_contents {
  background: #ececec;
  border: 1px solid #ddd;
  height: 300px;
  padding: 20px;
}
.two_blocks_row .block_contents p {
  padding-bottom: 5px;
}
.selected_count {
  width: 130px;
  height: 40px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 40px;
  color: rgb(8, 82, 243);

  box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.1);
  position: relative;

  margin-left: 20px;
}
.selected_count .material-icons {
  font-size: 40px;
  top: -1px;
  left: -10px;
  position: relative;
  margin-right: 0;
}
.selected_count span {
  display: inline-block;
  position: relative;
  top: -13px;
  left: -8px;
}
.lone_chk_label {
  padding: 0;
  display: inline-block;
  margin-left: 40px;
}
.lone_chk_label span {
  display: inline-block;
  position: relative;
  top: -5px;
}
.lone_chk_label input {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.chk_label_conf {
  padding: 0;
  display: inline-block;
}
.chk_label_conf input {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
}
.tandc_link {
  color: #f00;
  display: inline-block;
  margin-left: 20px;
  position: relative;
  top: -5px;
}
.bt_draft {
  background: #999;
  color: #000;
}
table.conf_adj_1 td:first-child {
  width: 300px;
}
</style>